@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: monospace;
}

@media (min-width: 640px) {
  .sm\:list-item {
    display: list-item;
  }
}
